.user-policy-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.user-policy-title {
    color: #3A77BC;
    font-size: 16px;
    font-weight: 400;
}

.single-day-tab-container {
    background-color: #79ACF9;
    height: 20px;
    width: 33px;
    border-radius: 4px;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
    cursor: pointer;
}

.policy-day-names-container {
    display: flex;
    gap: 8px;
}

.days-single-row-container {
    margin-bottom: 20px;
    background-color: #E7E7E7;
}

.single-time-pair-view-card {
    background-color: #B9D6FF;
    color: #3A77BC;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 24px;
    border-radius: 4px;
    margin-top: 10px;
    gap: 2px;
}

.single-container-time-pair-blocked-content {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.blocked-content-view-container {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    padding: 5px;
    border-radius: 4px;
    margin-top: 5px;
}

.all-unblocked-view {
    border: 1px solid #FFC9CE;
    background-color: #FFF7F7;
}

.all-blocked-view {
    border: 1px solid #B7FFC2;
    background-color: #F7FFF9;
}

.partial-blocked-view {
    border: 1px solid #FFD789;
    background-color: #FFFBE9;
}

.content-policy-single-row {
    display: flex;
    /* justify-content: space-around; */
    /* gap: 20px; */
}

.content-policy-single-entity-container {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
    flex: 1;
}