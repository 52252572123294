.fetch-devices-button {
    background: linear-gradient(0deg, #3A77BC, #3A77BC),
        linear-gradient(0deg, #FFC9CE, #FFC9CE);
    min-width: 100px;
    min-height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    font-size: 18px;
}

.search-devices-button {
    background: linear-gradient(0deg, #3A77BC, #3A77BC),
        linear-gradient(0deg, #FFC9CE, #FFC9CE);
    min-width: 250px;
    min-height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    font-size: 18px;
}


.search-devices-button .button-content {
    display: flex;
    align-items: center;
    /* Aligns items vertically in the center */
    justify-content: center;
    /* Centers content horizontally */
}

.search-devices-button img {
    margin-right: 10px;
    margin-top: 3px;
    /* Adds some space between the image and text */
}