/* Green Dot */
.green-dot {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: #4caf50;
    /* Green color */
    border-radius: 50%;
    display: inline-block;
}

/* Red Dot */
.red-dot {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: #f44336;
    /* Red color */
    border-radius: 50%;
    display: inline-block;
}