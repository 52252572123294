.antd-table-striped .ant-table-tbody>tr:nth-child(odd) {
    background-color: #f8f8f8;
    /* Light gray for odd rows */
}

.antd-table-striped .ant-table-tbody>tr:nth-child(even) {
    background-color: #ffffff;
    /* White for even rows */
}

.table-container-custom {
    border: 1px solid #E7E7E7;
}

.user-status-EXPIRED .ant-badge-count {
    color: #515151 !important;
    border: 1px solid #C3C3C3;
    background-color: #F6F6F6;
    line-height: 18px;
}

.user-status-PREMIUM .ant-badge-count {
    color: #515151 !important;
    border: 1px solid #BAD9F6 !important;
    background-color: #E2F1FF !important;
    line-height: 18px;
}

.user-status-FREE .ant-badge-count {
    color: #515151;
    border: 1px solid #BAF6C7;
    background-color: #EAFFE2;
    line-height: 18px;
}