@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-dropdown-menu-item-only-child {
  background-color: #3A77BC !important;
  color: white !important;
  margin-top: 5px !important;
}

.ant-table-thead>tr>th {
  color: #C0C0C0 !important;
  font-weight: 400;
  font-size: 12px;
  /* Replace #yourColorCode with the color you want */
}

.ant-table-tbody tr td {
  color: #515151 !important;
  font-weight: 400;
  font-size: 14px;
  /* Replace #yourColorCode with the color you want */
}

.link-class {
  color: #5E9BE3 !important;
  text-decoration: underline;
}

.border-card {
  border: 1px solid #E7E7E7 !important
}

.general-input-label {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
}

.general-form-input-item {
  height: 45px;
  /* border: 1px solid #979797 */
}

.general-form-title {
  text-align: center;
  color: #515151;
  font-weight: 300;
  font-size: 36px;
  margin-bottom: 30px;
}

.general-form-container {
  max-width: 450px;
}

.mt-20 {
  margin-top: 20px;
}

.span-unblocked {
  display: inline-block;
  color: #C30D1A;
  font-size: 16px;
  font-weight: 400;
}

.span-blocked {
  display: inline-block;
  color: #2F8E38;
  font-size: 16px;
  font-weight: 400;
}