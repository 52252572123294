.box-container {
    display: flex;
    gap: 15px;
}

.box-container>.box-item-heading {
    font-size: 14px;
    font-weight: 300;
    color: #515151;
}

.box-item-counter {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.box-item-counter>.box-item-counter-text {
    color: #3A77BC;
    font-size: 36px;
    font-weight: 300;
}