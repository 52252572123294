.border-package-card {
    border: 1px solid #E7E7E7
}

.package-card-top-items {
    display: flex;
    justify-content: space-between;
}

.package-header-title {
    color: #3A77BC;
    font-size: 16px;
    font-weight: 400;
}

.package-card-counters-label-container {
    display: flex;
    justify-content: space-between;
}

.package-card-counters-label-container>.label-field {
    color: #515151;
    font-size: 16px;
    font-weight: 300;
}

.package-card-counter-label-value {
    color: #515151;
    font-size: 16px;
    font-weight: 400;
}