.create-package-container {
    display: flex;
    justify-content: center;
    padding: 30px;
    padding-top: 50px;
    background: white;
}

.create-package-form {
    max-width: 450px;
}

.create-package-title {
    text-align: center;
    color: #515151;
    font-weight: 300;
    font-size: 36px;
    margin-bottom: 30px;
}

.package-input-label {
    color: #666666;
    font-size: 16px;
    font-weight: 400;
}

.package-form-input-item {
    height: 45px;
    /* border: 1px solid #979797 */
}

.package-form-select-item {
    height: 45px;
}

.package-volume-container {
    display: flex;
    justify-content: space-between;
}