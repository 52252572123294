.login-page-background {
    display: flex;
    min-height: 100vh;
    background-image: url('../../../assets//pngs/bg/LoginBackground.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.login-container {
    min-width: 80vw;
    min-height: 80vh;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
    margin-top: 10vh;
    margin-left: 10vw;
    z-index: 9999;
    max-height: 85vh;

}

.login-items {
    /* padding: 10px 20px 40px 20px; */
    height: 100%;
}

.login-right-container {
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
    justify-content: center !important;
}

.login-form-right-container {
    margin-left: 50px;
    font-family: 'Roboto', sans-serif;
}

.logo-login-form {
    height: 120px;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.login-form-title {
    font-size: 22px;
    font-weight: 400;
    color: #515151;
}

.login-form-description {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin-top: 10px;
}

.login-form-input-control {
    max-width: 300px;
}

.login-form-input-item {
    height: 45px;
    border: 1px solid #979797
}

.login-form {
    margin-top: 40px;
}

.login-form-input-label {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
}

.remember-me-forgot-password-container {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
}

.forgot-password-container {
    margin-top: 8px;
}

.forgot-password-label {
    color: #515151;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
}

.login-submit-button {
    background: linear-gradient(0deg, #3A77BC, #3A77BC),
        linear-gradient(0deg, #FFC9CE, #FFC9CE);
    min-width: 300px;
    min-height: 40px;
    font-size: 16px;
    font-weight: 400;
}