.border-site-card {
    border: 1px solid #E7E7E7
}

.site-card-top-items {
    display: flex;
    justify-content: space-between;
}

.site-title-box {
    display: flex;
    gap: 20px;
}

.site-title-box>.site-header-title {
    color: #3A77BC;
    font-size: 16px;
    font-weight: 400;
}

.site-header-address {
    color: #515151;
    font-size: 12px;
    font-weight: 400;
}

.site-card-package-details>.site-package-label {
    color: #51515199;
    font-size: 12px;
    font-weight: 300;
}

.site-card-package-details>.site-package-title {
    color: #515151;
    font-size: 16px;
    font-weight: 300;
}

.site-card-counters-container {
    display: flex;
    justify-content: space-between;
}

.site-card-counters-container>.site-card-counter-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.site-card-counter-box>.site-card-counters-title {
    font-size: 14px;
    color: #515151;
    font-weight: 300;
}

.site-card-counter-box>.site-card-counters-value {
    font-size: 14px;
    color: #3A77BC;
    font-weight: 300;
}