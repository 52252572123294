.day-container {
    border: 1px solid #E7E7E7;
    margin-bottom: 15px;
}

.cross-icon-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.policy-name-control {
    height: 45px;
    border: 1px solid #979797
}



.input-control-container {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}

.error-message-custom {
    color: red;
}

.time-slots-main-container {
    min-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.time-slots-policy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.day-label {
    color: #515151;
    font-size: 16px;
    font-weight: 400;
}

.single-time-slot-container {
    border: 1px dashed #DDDDDD;
    /* width: 80%; */
    padding: 10px;
    margin-top: 10px;
    border-radius: 12px;
}

.time-pair-container {
    display: flex;
    gap: 20px;
    min-height: 40px;
    align-items: center;
    padding-right: 10px;
    margin-top: 10px;
}

.time-parent-section {
    display: flex;
    border: 1px solid #E7E7E7;
    padding-left: 5px;
    min-height: 60px;
    align-items: center;
    min-width: 82%;
    justify-content: space-between;
    border-radius: 10px;
}

.time-pair-section {
    display: flex;
    min-height: 60px;
    align-items: center;
    padding: 0px;
    background: #E7E7E7;
    border-radius: 10px;
    min-width: 70%;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
}

.time-pair-section-label {
    color: #515151;
    font-size: 16px;
    font-weight: 400;
    margin-top: 3px;
}

.single-time-entity-container {
    display: flex;
    gap: 10px;
}

.custom-select-for-time {
    height: 32px;
    border-radius: 4px;
    /* border: none; */
    width: 80px;
    border: 1px solid #EDEDED
}

.remove-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    min-width: 16%;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    cursor: pointer;
}

.content-parent-section {
    display: flex;
    border: 1px solid #E7E7E7;
    border-right: none;
    padding-left: 5px;
    min-height: 60px;
    align-items: center;
    width: 80%;
    justify-content: space-between;
    border-radius: 10px;
}

.block-content-label {
    color: #D00412;
    font-size: 16px;
    font-weight: 400;
}

.time-slot-content-label {
    color: #515151;
    font-size: 16px;
    font-weight: 400;
}

.block-content-section {
    display: flex;
    min-height: 60px;
    align-items: center;
    padding: 0px;
    background: #FFF7F7;
    border-radius: 10px;
    min-width: 70%;
    justify-content: center;
    gap: 40px;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid #FFC9CE;

}

.single-content-policy-container {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}



.add-new-day-button {
    background: #B4D334;
    min-width: 200px;
    font-weight: 400;
    font-size: 18px;
    min-height: 40px;
}

.create-time-policy-button {
    background: #3A77BC;
    min-width: 200px;
    font-weight: 400;
    font-size: 18px;
    min-height: 40px;
}

.control-buttons-container {
    display: flex;
    justify-content: center;
    gap: 40px;

}