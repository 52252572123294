.user-details-left-container-row {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;

}

.user-fullName {
    color: #3A77BC;
    font-size: 16px;
    font-weight: 400;
}

.user-bg-gray {
    background: #F8F8F8 !important;
    border-radius: 5px;
}

.user-details-value-container {
    display: flex;
    justify-content: flex-start;
    min-width: 33%;
}

.user-details-label-container {
    width: 33%;
    max-width: 33%;
}

.user-right-container-title {
    color: #3A77BC;
    font-size: 16px;
    font-weight: 400;
}

.search-users-button {
    background: linear-gradient(0deg, #3A77BC, #3A77BC),
        linear-gradient(0deg, #FFC9CE, #FFC9CE);
    min-height: 40px;
    font-size: 1px;
}