.logo {
    height: 120px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25%;
    display: flex;
    justify-content: space-between;
}

.logo img {
    max-height: 60%;
}

.side-bar-main {
    border-inline-end: 1px solid#E7E7E7;
    min-width: 200px !important;
    max-width: 200px !important;
    width: 200px !important;
}

.ant-menu-item-selected {
    background-color: #f5f5f5 !important;
    color: #515151 !important;
    font-weight: 400;
    font-size: 16px;
}

.ant-menu-item:not(.ant-menu-item-selected) {
    color: #515151 !important;
    font-weight: 400;
    font-size: 16px;
}

.ant-menu-item-icon {
    max-width: 20px;
    width: 20px;
}

.ant-menu-title-content {
    text-align: left;
}

.top-nav-bar-custom {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    min-width: 100% !important;
}

.top-nav-bar-items-container {
    display: flex;
    gap: 12px;
    margin-right: 20px;
}

.top-nav-bar-item {
    border: 1px solid #e7e7e7;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 7px;
    cursor: pointer;
}

.current-active-page-title {
    font-weight: 400;
    font-size: 22px;
    color: #515151;
}

.active-page-header-container {
    display: flex;
    gap: 15px;
}