.user-profile-details-container {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.user-profile-icon {
    background: url('./../../../assets/pngs/ProfileBackground.png') no-repeat;
    width: 150px;
    height: 150px;
    /* background: black; */
    /* color: white; */
    text-align: center;
    /* line-height: 50px; */
    font-size: 24px;
    /* border-radius: 50%; */
    margin-top: -60px;
}

.user-profile-initial {
    margin-left: -63px;
    font-size: 30px;
    font-weight: 700;
    color: white;
    padding-top: 15px;
}

.user-profile-name {
    color: #515151;
    font-size: 22px;
    font-weight: 400;
}

.user-profile-details-row {
    max-width: 500px;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.profile-container {
    display: flex;
    justify-content: center;
    margin-bottom: -100px;
    position: relative;
    z-index: 9999;
}

.user-full-name-container {
    max-width: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
}

.user-profile-details-label {
    color: #868686;
    font-size: 18px;
    font-size: 300;
}

.user-profile-details-value {
    color: #505050;
    font-size: 18px;
    font-size: 400;
}

.user-profile-single-item-label {
    color: #969696;
    font-size: 14px;
    font-size: 300;
}

.user-profile-single-item-value {
    color: #515151;
    font-size: 24px;
    font-size: 300;
}

.single-item-user-profile {
    display: flex;
    gap: 20px;
}

.bottom-buttons-user-profile {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.edit-profile-button {
    background-color: #B4D334;
}

.change-password-button {
    background: #3A77BC;
}

/* Update profile */
.update-profile-heading {
    font-size: 22px;
    font-weight: 400;
    color: #515151;
}

.update-profile-description {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
}

.update-profile-input-item {
    height: 45px;
    border: 1px solid #979797
}

.update-profile-form-input-label {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
}

.update-profile-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}