.border-card {
    border: 1px solid #E7E7E7
}

.single-site-mid-card {
    height: 170px;
    width: 100%;
    border-radius: 8px;
}

.mid-card-1 {
    background: #79ACF9;
}

.mid-card-2 {
    background: #9FC6FF;
}

.mid-card-3 {
    background: #B9D6FF;
}

.mid-card-title-container {
    display: flex;
    padding: 25px;
    gap: 10px;
}

.mid-card-title-container>.mid-card-box-title {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 300;
}

.mid-card-box-value-container {
    display: flex;
    justify-content: center;
}

.mid-card-box-value-container>.mid-card-box-value {
    font-size: 48px;
    color: white;
    font-weight: 500;
}

.single-site-card-router-label {
    font-size: 18px;
    color: #666666;
    font-weight: 400;
}

.router-details-label-container {
    display: flex;
    justify-content: space-between;
}

.router-details-label-container>.router-details-label {
    font-size: 16px;
    font-weight: 400;
    color: #666666 !important;
}

.router-details-value {
    font-size: 16px;
    font-weight: 400;
    color: #515151;
}

.router-restart-button-container {
    background-color: #3A77BC;
    border: 1px solid #E7E7E7;
    min-height: 150px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.router-restart-button-container .restart-router-label {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
}