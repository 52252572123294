:root {
    --white: white;
    --gray: #999;
    --lightgray: whitesmoke;
    --darkgreen: #3a77bc;
}

.switch-container {
    text-align: center;
}

.switch-wrapper {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    padding: 4px;
    border: 1px solid lightgrey;
    /* margin-bottom: 40px; */
    border-radius: 30px;
    background: var(--white);
}

.switch-wrapper [type="radio"] {
    position: absolute;
    left: -9999px;
}

.switch-wrapper [type="radio"]:checked#monthly~label[for="monthly"],
.switch-wrapper [type="radio"]:checked#yearly~label[for="yearly"] {
    color: var(--white);
}

.switch-wrapper [type="radio"]:checked#monthly~label[for="monthly"]:hover,
.switch-wrapper [type="radio"]:checked#yearly~label[for="yearly"]:hover {
    background: transparent;
}

.switch-wrapper [type="radio"]:checked#monthly+label[for="yearly"]~.highlighter {
    transform: none;
}

.switch-wrapper [type="radio"]:checked#yearly+label[for="monthly"]~.highlighter {
    transform: translateX(100%);
}

.switch-wrapper label {
    font-size: 16px;
    z-index: 1;
    min-width: 100px;
    line-height: 32px;
    cursor: pointer;
    border-radius: 30px;
    transition: color 0.25s ease-in-out;
}

.switch-wrapper label:hover {
    background: var(--lightgray);
}

.switch-wrapper .highlighter {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    border-radius: 30px;
    background: var(--darkgreen);
    transition: transform 0.25s ease-in-out;
}