.voucher-status-unused {
    display: inline-block;
    min-width: 70px;
    height: 30px;
    line-height: 25px;
    background: #F7F9FF;
    border: 1px solid #DFF0FF;
    border-radius: 10px;
    text-align: center;
    color: #3A77BC;
}

.voucher-status-used {
    background: #FFEAEA;
    display: inline-block;
    min-width: 70px;
    height: 30px;
    line-height: 25px;
    border: 1px solid #FFD5D5;
    border-radius: 10px;
    text-align: center;
    color: #FF0000;
}