.batch-card-top-items {
    display: flex;
    justify-content: space-between;
}

.batch-header-title {
    color: #515151;
    font-size: 16px;
    font-weight: 700;
}

.batch-card-container {
    height: 200px;
}

.batch-speed-label {
    font-size: 16px;
    font-weight: 400;
    color: #3A77BC;
}

.batch-duration-label {
    font-size: 10px;
    font-weight: 300;
    color: #619FFB;
}

.batch-left-container {
    display: flex;
    justify-content: space-between;
}

.batch-left-container-values {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
}

.batch-volume-label {
    color: #595959;
    font-size: 14px;
    font-weight: 300;
}

.batch-policy-label {
    color: #A0A0A0;
    font-size: 13px;
    font-weight: 300;
}

.vertical-divider {
    height: 120px;
    border-left: 1px solid #DEDEDE;
}

.batch-details-label {
    color: #868686;
    font-size: 14px;
    font-weight: 300;
}

.batch-details-value {
    color: #505050;
    font-size: 14px;
    font-size: 400;
}

.batch-right-container {
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;
}

.batch-card-disabled {
    background: linear-gradient(0deg, rgba(255, 0, 0, 0.03), rgba(255, 0, 0, 0.03)),
        linear-gradient(0deg, #FFC9CE, #FFC9CE);
    border: 1px solid #FFC9CE !important;
    text-decoration: line-through;
}